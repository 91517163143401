@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  box-sizing: border-box;
}
html,
body {
  min-height: 100%;
  background: rgba(40, 42, 46) !important;
  color: #000;
  font-family: "Poppins", sans-serif;
  max-width: 1920px;
  min-width: 375px !important;
  margin: 0;
  padding: 0;
}
/* sets the body height of the browser, so that backgrounds and div heights work correctly. Color and background will almost certainly be altered; they are just placeholders */

body {
  font-size: 1.4rem;
  text-rendering: optimizeLegibility;
}
/* sets the base font size for the document; turns on kerning pairs and ligatures */

body,
ul,
ol,
dl {
  margin: 0;
}
article,
aside,
audio,
footer,
header,
nav,
section,
video {
  display: block;
}

/* ensures that older browsers will display HTML5 elements correctly */

h1 {
  font-size: 1.4rem;
}
/* begins to set up font sizes relative to base rem – h1 has been used as an example */

p {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
/* sets hyphenation by default on paragraphs */

textarea {
  resize: vertical;
}
/* changes textarea resizing from "both" (UA default) to vertical only */

table {
  border-collapse: collapse;
}
td {
  padding: 0.5rem;
}
/* causes tables to actually look like tables */

img {
  border: none;
  max-width: 100%;
}
/* removes outlines from linked images (for older browsers and IE), sets up images for responsive design layout */

input[type="submit"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner {
  border: 0px;
}
/* removes the inner border effect from focused buttons for form elements in Firefox */

input[type="search"] {
  -webkit-appearance: textfield;
}
input[type="submit"] {
  -webkit-appearance: none;
}
/* removes the OS X appearance from HTML5 search inputs and submit buttons when viewed in Safari */

input:required:after {
  color: #f00;
  content: " *";
}
/* sets up required form fields with the conventional following red asterix */
input[type="email"]:invalid {
  background: #f00;
}
/* causes a visual alert in browsers that do client-side checking of HTML5 email field – this may already be the default in some browsers. */

.right {
  float: right;
  margin-left: 2rem;
  clear: right;
}
.left {
  float: left;
  margin-right: 2rem;
  clear: left;
}
/* allows a quick shortcut for floating elements left and right while avoiding the “float quirk” problem */

sub,
sup {
  line-height: 0;
}

.fc-day-today {
  background: #808080 !important;
  color: #fcfcff;
}

.fc-event-main {
  height: 21px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin: 0 0 0 0;
  text-transform: capitalize;
}

.fc-daygrid-event-harness {
  margin: 0px auto 3px auto !important;
  width: 97%;
  height: auto;
}

.fc-daygrid-more-link {
  font-size: 13px;
  margin: 5px 0 0 0 !important;
  padding: 6px 12px 6px 5px !important;
  color: #fcfcff !important;
  background-color: #3788d8 !important;
  width: auto;
  font-weight: 300;
}
.fc-col-header-cell {
  font-weight: 300;
}

.fc-daygrid-more-link:hover {
  color: #fcfcff !important;
  background-color: #3788d8 !important;
}

.fc-daygrid-day-events {
  cursor: pointer;
}

.fc-popover-title {
  padding: 8px 0px 0px 15px !important;
  color: #fcfcff;
  font-size: 18px;
  border-radius: 4px;
}
.fc-event-title {
  font-weight: 300;
  font-size: 13px;
}

@media only screen and (max-width: 1021px) {
  .fc-event-title {
    font-size: 12px;
  }
}
@media only screen and (max-width: 985px) {
  .fc-event-title {
    font-size: 11px;
  }
}

@media only screen and (max-width: 900px) {
  .fc-event-title {
    font-size: 10px;
  }
}

@media only screen and (max-width: 850px) {
  .fc-event-title {
    font-size: 9.8px;
  }
}

.fc-popover-close {
  padding: 4px 0 2px 0px !important;
}
.fc-popover-body {
  background-color: #808080 !important;
  border-radius: 0 0 4px 4px !important;
}
.fc-popover-header {
  background-color: #808080 !important;
  border-radius: 4px 4px 0 0 !important;
}
.fc-popover {
  border-radius: 4px !important;
  border: none !important;
}
.fc-icon {
  color: #f23645;
  font-size: 1.2em !important;
}

.fc-daygrid-day-frame {
  height: 185px;
}

.fc-event {
  cursor: pointer !important;
  font-weight: 300;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.fc-toolbar-title {
  font-size: 1.3em !important;
  margin-left: 5px !important;
  margin-top: 10px !important;
  font-weight: 300;
}

.fc-today-button {
  font-size: 0.8em !important;
  padding: 8.5px 15px !important;
  margin-top: 10px !important;
}
.fc-prev-button {
  margin-top: 10px !important;
  padding: 2px 10px 4px 10px !important;
  height: 45px !important;
}

.fc-next-button {
  margin-top: 10px !important;
  padding: 2px 10px 4px 10px !important;
  height: 45px !important;
  margin-right: 5px !important;
}

/* Mobile calendar */

.fc-list-day-cushion {
  font-weight: 300;
}

.fc-listWeek-view {
  border: none !important;
  height: 100%;
}

.fc-list-table th {
  color: black;
  background-color: white !important;
  font-size: 18px;
}

.fc-list-table {
  background-color: transparent !important;
  border: none !important;
  margin: 0 auto !important;
  margin-bottom: 1em !important;
}

.fc-list-table td {
  vertical-align: bottom;
}

.fc-list-table td:hover {
  background-color: transparent !important;
}

.fc-list-event-graphic {
  margin-left: 5px !important;
  background-color: transparent !important;
}
.fc-list-event-dot {
  margin: 0 !important;
}

.fc-list-event-time {
  display: none;
}
.fc-list-event-title {
  font-size: 17px;
}

.fc-list-event-title:hover {
  font-size: 18px;
}

.fc-list-empty {
  background-color: transparent !important;
}

/* profile settings */
.MuiMenu-paper {
  border-radius: 0px !important;
}

@media only screen and (max-width: 1140px) {
  .fc-daygrid-more-link {
    font-size: 10px;
  }
  .fc-toolbar-title {
    font-size: 0.75em !important;
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 789px) {
  .fc-scroller {
    height: 69vh !important;
  }
}

@media only screen and (max-width: 1180px) {
  .fc-scroller {
    overflow: scroll !important;
    
  }
}

.fc-toolbar.fc-header-toolbar {
  width: 100% !important;
  margin: 0 auto 1em auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 789px) {
  .fc-toolbar.fc-header-toolbar {
    width: 95% !important;
  }
  .fc-media-screen {
    min-height:80vh;
  }
}




svg {
  display: none;
}

.styles-module_navigation__1pqAE {
  display: none !important;
}

.styles-module_next__1uQwZ {
  display: none !important;
}

.react-simple-image-viewer__next {
  display: none !important;
}

.react-pdf__message--error {
  display: none;
}

.tableHoverEffectDark tr:hover td {
  background-color: rgb(90, 90, 90);
  cursor: "pointer";
}
.tableHoverEffectDark tr:hover th {
  background-color: rgb(90, 90, 90);
  cursor: "pointer";
}

.tableHoverEffectLight tr:hover td {
  background-color: rgb(210, 210, 210);
  cursor: "pointer";
}
.tableHoverEffectLight tr:hover th {
  background-color: rgb(210, 210, 210);
  cursor: "pointer";
}


.tableHoverEffectOff {
  pointer-events: none;
}


